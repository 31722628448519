/* jQuery Countdown styles 1.6.3. */
.hasCountdown {
	background-color: transparent;
}
.countdown_rtl {
	direction: rtl;
}
.countdown_holding span {
	color: #888;
}
.countdown_row {
	clear: both;
	width: 50%;
	padding: 0px 2px;
	text-align: center;
}
.countdown_show1 .countdown_section {
	width: 98%;
}
.countdown_show2 .countdown_section {
	width: 48%;
}
.countdown_show3 .countdown_section {
	width: 32.5%;
}
.countdown_show4 .countdown_section {
	width: 24.5%;
}
.countdown_show5 .countdown_section {
	width: 19.5%;
}
.countdown_show6 .countdown_section {
	width: 16.25%;
}
.countdown_show7 .countdown_section {
	width: 14%;
} 
.countdown_section {
	display: block;
	float: left;
	font-size: 17px;
	text-align: center;
	text-transform:uppercase;
	letter-spacing:3px;
}
.countdown_amount {
	font-weight:100;
	font-size: 50px;
}
.countdown_descr {
	display: block;
	width: 100%;
	
}


@media (max-width: 767px) {

.countdown_section {
	display: block;
	float: left;
	font-weight:700;
	font-size: 11px;
	text-align: center;
	text-transform:uppercase;
	letter-spacing:3px;
}
.countdown_amount {
	font-weight:100;
	font-size: 30px;
}
.countdown_descr {
	display: block;
	width: 100%;
	
}

}